<template>
  <PatologiesPage
    title="Acalasia esofagea"
    subtitle1="L’acalasia è una patologia rara che colpisce all’incirca una persona su centomila e si manifesta con disturbi del transito alimentare. Si verifica una perdita dei neuroni che innervano l’esofago e lo sfintere esofageo inferiore: il primo perde quindi i suoi movimenti peristaltici che contribuiscono alla progressione del bolo alimentare, il secondo non si rilascia più adeguatamente alla deglutizione, ostacolando quindi l’entrata del cibo stesso in esofago. Il risultato di tali alterazioni è che il cibo e la saliva non transitano più efficacemente attraverso l’esofago nello stomaco e tendono a ristagnare nel primo ed a risalire in bocca, determinando i due sintomi principali della malattia che sono la disfagia (una sensazione di arresto del transito del bolo alimentare) ed il rigurgito (una risalita in bocca di cibo deglutito e non digerito o saliva). Altri sintomi dell’acalasia possono essere il dolore toracico, l’alitosi, la perdita di peso. Spesso accade che, in virtù di tali caratteristiche di presentazione, giovani donne vengano etichettate come affette da anoressia nervosa quando in verità sono affette da acalasia."
    subtitle2="Per porre una corretta diagnosi di acalasia, è necessario che il paziente esegua una EGDS (gastroscopia), un radiografia detta Rx delle prime vie digestive in cui dovrà bere del mezzo di contrasto, ed infine una manometria esofagea standard o ad alta risoluzione (che misura la funzione motoria dell’esofago), che ci permetterà di porre la diagnosi di certezza."
    subtitle3="Non essendo possibile restituire all’esofago la sua innervazione naturale recuperando la sua motilità, ogni tipo di trattamento è teso a rimuovere l’ostacolo al transito a valle dell’esofago che è rappresentato dallo sfintere esofageo inferiore, permettendo al cibo di transitare agevolmente attraverso l’esofago in stomaco, di fatto per gravità.

I trattamenti possibili possono essere raggruppati essenzialmente in 3 categorie:

TRATTAMENTI FARMACOLOGICI:

prevedono l’utilizzo di farmaci che inducono un blando rilassamento dello sfintere esofageo inferiore, quali i calcio-antagonisti ed i nitrati. La loro efficacia è però molto limitata e pertanto di fatto vengono utilizzati quali sintomatici nell’attesa di un trattamento endoscopico o chirurgico.

TRATTAMENTI ENDOSCOPICI:

Iniezione di tossina botulinica: l’iniezione endoscopica di tale tossina induce un rilasciamento dello sfintere esofageo inferiore. L’efficacia di tale trattamento è però bassa (circa il 60%) e di breve durata. Viene pertanto riservato a pazienti che non possano essere trattati con altre metodiche. Viene effettuata usualmente in regime ambulatoriale o di day-hospital.

Dilatazione endoscopica: si esegue dilatando lo sfintere esofageo inferiore con un palloncino gonfiabile (Rigiflex) in corso di endoscopia. L’efficacia è buona (70-80%) ma spesso tale trattamento richiede di essere ripetuto, in quanto dopo qualche mese si possono ripresentare i sintomi. Tale metodica inoltre, abolendo di fatto la funzione di valvola dello sfintere esofageo inferiore, espone il paziente al rischio di insorgenza di reflusso gastro-esofageo. Trova la sua maggiore indicazione in pazienti che non possano essere sottoposti a trattamento chirurgico per alto rischio operatorio e nel trattamento delle recidive post-chirurgiche. Viene effettuata usualmente in day-hospital.

P.O.E.M.: questa metodica prevede l’esecuzione di una miotomia (sezione delle fibre muscolari) dello sfintere esofageo inferiore e dell’esofago distale per via endoscopica (EGDS). Essendo la miotomia molto efficace, il tasso di successo di questa procedura è molto alto (circa il 90%). Tuttavia, proprio per la sua efficacia nell’abolizione dell’effetto valvola dello sfintere esofageo inferiore, è però gravata da una incidenza di reflusso gastro-esofageo non trascurabile. Trova la sua maggiore indicazione in pazienti che non possano essere sottoposti a trattamento chirurgico per alto rischio operatorio e nel trattamento delle recidive post-chirurgiche. La sua esecuzione richiede un ricovero usualmente di 3 giorni.

TRATTAMENTI CHIRURGICI:

Miotomia e plastica antireflusso secondo Heller-Dor per via  laparoscopica: questo intervento eseguito per via mini-invasiva prevede l’esecuzione di una miotomia (sezione) dello sfintere esofageo inferiore e dell’esofago distale ed il confezionamento di una plastica antireflusso. E’ una procedura molto efficace con una percentuale di successo molto alta (> 90%) con un’ottima durata nel tempo e gravata da una bassa incidenza di reflusso gastro-esofageo, proprio perché combinata ad una plastica antireflusso. La sua esecuzione richiede un ricovero usualmente di 3 giorni. Dopo l’operazione il paziente deve seguire una dieta con cibi morbidi per una decina di giorni e successivamente viene consentita una dieta libera.

Esofagectomia: è una procedura che prevede l’asportazione dell’esofago e la ricostruzione del tratto gastrointestinale mediante la trasposizione al collo dello stomaco o di un tratto di colon. Può essere effettuato anch’esso con approccio mini-invasivo laparoscopico e toracoscopico. E’ un intervento che viene effettuato molto di rado in quanto è riservato solo a pazienti che presentino un esofago estremamente dilatato (megaesofago) ed in cui la miotomia secondo Heller-Dor non abbia avuto successo. Prevede generalmente un ricovero di circa 15 giorni."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Acalasia",
  components: {
    PatologiesPage,
  },
};
</script>
